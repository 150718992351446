import React from "react"
import { CheckIcon } from '@heroicons/react/outline'

import Layout from "../components/Layout"
import Seo from "../components/seo"

const JobsPage = () => (
  <Layout>
    <Seo title="Post a job" />
    <div className="max-w-7xl mx-auto px-4 mt-6 mb-24">
      <div className="">
        <h1 className="text-gray-300 text-xl md:text-2xl tracking-wider font-semibold">Post a Job</h1>
      </div>

      <div className="mt-16">
        <h2 className="text-gray-200 text-5xl lg:text-7xl font-black">The future of work meets<br className="hidden xl:block" /> the future of web.</h2>
        <h3 className="mt-10 text-blue-500 text-2xl lg:text-3xl">CryptoRemote.dev connects remote blockchain developers with the best crypto companies.</h3>
      </div>

      <div className="mt-16">
        <h2 className="text-gray-200 text-3xl lg:text-4xl font-bold">Simple and clear pricing</h2>
      </div>

      <div className="flex flex-col md:flex-row items-start mt-2">
        <div className="w-full md:w-6/12 xl:w-4/12 flex flex-col items-center justify-center bg-site-color-brand-3 mt-8 px-12 py-14 rounded-2xl">
          <div className="flex items-center">
            <h3 className="text-gray-200 text-7xl font-semibold">$199</h3>
            <span className="block text-gray-300 text-2xl font-medium ml-3">USD</span>
          </div>
          <div className="mt-12">
            <p className="text-gray-300 text-xl mb-6 text-center">Let us help you find your next great remote crypto, blockchain and web3 developer.</p>
            <a
              href="https://form.jotform.com/213142499543054"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="w-full text-green-100 bg-green-600 hover:bg-green-700 hover:text-white px-10 py-4 rounded-md text-2xl font-semibold">
                Get Started
              </button>
            </a>
          </div>
        </div>
        <div className="w-full md:w-6/12 xl:w-8/12 mt-4 pt-3 px-0 md:px-12">
          <h3 className="text-gray-300 text-xl lg:text-2xl mb-6 font-bold">Job post inclusions</h3>
          <div className="flex items-center">
            <CheckIcon className="block text-green-500 h-8 w-8 flex-shrink-0 mr-5 -mt-2" aria-hidden="true" />
            <p className="text-gray-300 text-lg lg:text-xl mb-3">Featured job post for 30 days in curated and category pages</p>
          </div>
          <div className="flex items-center">
            <CheckIcon className="block text-green-500 h-8 w-8 flex-shrink-0 mr-5 -mt-2" aria-hidden="true" />
            <p className="text-gray-300 text-lg lg:text-xl mb-3">Display your company logo</p>
          </div>
          <div className="flex items-center">
            <CheckIcon className="block text-green-500 h-8 w-8 flex-shrink-0 mr-5 -mt-2" aria-hidden="true" />
            <p className="text-gray-300 text-lg lg:text-xl mb-3">Dedicated company profile page</p>
          </div>
          <div className="flex items-center">
            <CheckIcon className="block text-green-500 h-8 w-8 flex-shrink-0 mr-5 -mt-2" aria-hidden="true" />
            <p className="text-gray-300 text-lg lg:text-xl mb-3">Included in our newsletter as featured job post</p>
          </div>
          <div className="flex items-center">
            <CheckIcon className="block text-green-500 h-8 w-8 flex-shrink-0 mr-5 -mt-2" aria-hidden="true" />
            <p className="text-gray-300 text-lg lg:text-xl mb-3">Social media posts</p>
          </div>
          <div className="flex items-center">
            <CheckIcon className="block text-green-500 h-8 w-8 flex-shrink-0 mr-5 -mt-2" aria-hidden="true" />
            <p className="text-gray-300 text-lg lg:text-xl mb-3">No need to create account or sign-up</p>
          </div>
          <div className="flex items-center">
            <CheckIcon className="block text-green-500 h-8 w-8 flex-shrink-0 mr-5 -mt-2" aria-hidden="true" />
            <p className="text-gray-300 text-lg lg:text-xl mb-3">Customer support via email</p>
          </div>
        </div>
      </div>

      <div className="mt-16">
        <h2 className="text-gray-200 text-3xl lg:text-4xl font-bold">Contact Us</h2>
        <p className="mt-10 text-gray-300 text-xl lg:text-2xl">If you need help with your job postings or have any questions, just reach out and we'll get back to you within 24 hours, Monday to Friday.</p>
        <div className="mt-12">
          <a
            href="https://form.jotform.com/213305018080442"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full lg:w-auto text-indigo-100 bg-indigo-600 hover:bg-indigo-700 hover:text-white px-10 py-4 rounded-md text-xl font-semibold"
          >
            Send Message
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default JobsPage
